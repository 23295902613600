<script setup lang="ts"></script>

<template>
    <router-view v-slot="{ Component }">
        <transition
            name="blur"
            mode="out-in"
        >
            <component
                :is="Component"
                :key="$route.path"
            />
        </transition>
    </router-view>
</template>

<style lang="less"></style>
