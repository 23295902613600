<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 14 14"
    >
        <path
            fill="fillColor"
            d="M7.994 1.727c.3905-.3904.8226-.2948 1.2131.0957l4.5 4.5c.3905.3905.3905 1.0237 0 1.4142l-4.5 4.5c-.3905.3905-.8573.3754-1.2478-.0151-.3905-.3905-.1158-.8974.2747-1.288l3.166-3.104H1c-.5523 0-1-.2477-1-.8s.4478-.7884 1-.8h10.4l-3.307-3.293c-.3905-.3905-.4895-.8192-.099-1.2097z"
        />
    </svg>
</template>
