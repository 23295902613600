<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        class="icon"
        viewBox="0 0 20 20"
    >
        <path
            fill="fillColor"
            d="M18 18H2c-1.1046 0-2-.8954-2-2V4c0-1.1046.8954-2 2-2h16c1.1046 0 2 .8954 2 2v12c0 1.1046-.8954 2-2 2zM1.5 9.5v7h17v-7zm0-6V6h17V3.5ZM11 14H4v-2h7z"
        />
    </svg>
</template>
