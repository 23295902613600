var tt = Object.defineProperty;
var nt = (t, e, n) => e in t ? tt(t, e, { enumerable: !0, configurable: !0, writable: !0, value: n }) : t[e] = n;
var T = (t, e, n) => (nt(t, typeof e != "symbol" ? e + "" : e, n), n), le = (t, e, n) => {
  if (!e.has(t))
    throw TypeError("Cannot " + n);
};
var l = (t, e, n) => (le(t, e, "read from private field"), n ? n.call(t) : e.get(t)), f = (t, e, n) => {
  if (e.has(t))
    throw TypeError("Cannot add the same private member more than once");
  e instanceof WeakSet ? e.add(t) : e.set(t, n);
}, h = (t, e, n, i) => (le(t, e, "write to private field"), i ? i.call(t, n) : e.set(t, n), n);
var Ce = (t, e, n, i) => ({
  set _(r) {
    h(t, e, r, n);
  },
  get _() {
    return l(t, e, i);
  }
}), d = (t, e, n) => (le(t, e, "access private method"), n);
import it from "axios";
import rt from "lodash.debounce";
class I extends Error {
  /**
   * Create new instance of the error
   * @param message
   * @param context
   * @param params
   */
  constructor(n, i, r) {
    super(n);
    T(this, "_context", null);
    T(this, "_isCritical", !1);
    if (this.name = "CgError", i && (this._context = i), r && typeof r == "object") {
      const { cause: o, critical: s } = r;
      o instanceof Error && (this.cause = o), typeof s == "boolean" && (this._isCritical = s);
    }
  }
  /**
   * Returns if error is critical
   */
  isCritical() {
    return this._isCritical;
  }
  /**
   * Returns string representation of the error
   */
  toString() {
    let n = `${this._isCritical ? "[CRITICAL] " : ""}[${this.name}] ${this.message}`;
    return this._context && (n += ` Context: ${JSON.stringify(this._context)}`), this.cause && (n += ` Cause: [${this.cause.toString()}]`), n;
  }
}
function ot(t) {
  return new Promise((e) => {
    const n = setTimeout(() => {
      e(n);
    }, t);
  });
}
function ge(t) {
  if (t) {
    const e = Number.parseFloat(t);
    if (isFinite(e))
      return e;
  }
  return Number.NaN;
}
function De(t, e, n = !1, i = null) {
  const r = ge(t), o = (s) => n && Math.floor(s) >= e ? Math.floor(s) : s;
  return typeof r == "number" && r >= e ? o(r) : o(i ? i < e ? e : i : e);
}
function Ae(t, e, n = !1, i = null) {
  const r = ge(t), o = (s) => n && Math.ceil(s) <= e ? Math.ceil(s) : s;
  return typeof r == "number" && r < e ? o(r) : o(i ? i > e ? e : i : e);
}
function H(t, e, n, i = !1, r = !1, o = "lower") {
  let s = n;
  return o === "lower" ? s = e : Number.isFinite(o) && (s = o), De(Ae(t, n, i, s), e, r, s);
}
function st() {
  let t = !1;
  try {
    t = window.navigator.javaEnabled();
  } catch {
  }
  return {
    language: window.navigator.language,
    colorDepth: window.screen.colorDepth,
    screenHeight: window.screen.height,
    screenWidth: window.screen.width,
    timezone: (/* @__PURE__ */ new Date()).getTimezoneOffset(),
    javaEnabled: t,
    javascriptEnabled: !0
  };
}
function at(t = !1) {
  const e = {
    isSecureContext: window.isSecureContext
  }, n = ["vendor", "connection", "hardwareConcurrency", "cookieEnabled", "appCodeName", "appName", "appVersion", "platform", "product", "userAgent", "language", "onLine", "userAgentData", "globalPrivacyControl", "maxTouchPoints", "productSub", "userActivation", "webdriver", "doNotTrack"], i = navigator;
  for (const r of n)
    r in i && ![null, void 0].includes(i[r]) && (typeof i[r] == "object" ? Object.keys(i[r]).length > 0 && (e[r] = i[r]) : e[r] = i[r]);
  return t ? " Navigator: " + JSON.stringify(e) : e;
}
function Re() {
  return crypto && "randomUUID" in crypto ? crypto.randomUUID() : Ie(16);
}
function Ie(t, e = "-") {
  return (Array.isArray(t) ? t : [t]).map((i) => {
    let r = "";
    do
      r += Math.floor(Math.random() * Date.now()).toString(32);
    while (r.length < i);
    return r.slice(0, i);
  }).join(e);
}
function lt(t, e = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz") {
  let n = "";
  const i = e.length;
  let r = 0;
  for (; r < t; )
    n += e.charAt(Math.floor(Math.random() * i)), r += 1;
  return n;
}
function ct(t, e = 1) {
  return !(typeof t == "string" && t.trim().length >= e);
}
function ut(t) {
  return t == null || // Check for null or undefined
  typeof t == "string" && t.trim() === "" || // Check for empty string
  Array.isArray(t) && t.length === 0 || // Check for empty array
  t.constructor === Object && Object.keys(t).length === 0;
}
async function ft(t) {
  return new Promise((e, n) => {
    try {
      if (typeof t.foundValidator == "function" && t.foundValidator()) {
        t.id, e(!0);
        return;
      }
      if (document.getElementById(t.id))
        t.foundValidator ? typeof t.foundValidator != "function" ? (console.warn("FoundValidator is not a function"), e(!1)) : t.foundValidator() === !0 ? (t.id, e(!0)) : (t.id, n("Script injected to the DOM but not loaded.")) : (t.id, e(!1));
      else {
        let r = null;
        if (t.elementType === "script")
          r = document.createElement("script"), r.src = t.link, r.async = t.async ?? !1, r.defer = t.defer ?? !1;
        else if (t.elementType === "link")
          r = document.createElement("link"), r.href = t.link, r.rel = t.rel, r.as = t.as;
        else
          throw new I("Invalid elementType: " + t.elementType);
        r.id = t.id, r.addEventListener("load", () => {
          typeof t.foundValidator == "function" && t.foundValidator() === !0 ? (t.id, e(!0)) : e(!1);
        }), t.elementType !== "link" && r.addEventListener("error", (o) => {
          t.id, n(o);
        }), document.head.append(r);
      }
    } catch (i) {
      t.id, n(i);
    }
  });
}
function dt(t) {
  return t instanceof I ? t.toString() : t instanceof Error ? `${t.name}: ${t.message}
${t.stack}` : typeof t == "object" && t !== null ? JSON.stringify(t, Object.getOwnPropertyNames(t)) : String(t);
}
const ht = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  appendScript: ft,
  bothBarrier: H,
  delayedPromise: ot,
  errorToString: dt,
  getBrowserInfo: st,
  getNavigatorDump: at,
  getPseudoRandom: Ie,
  getPseudoRandomString: Re,
  getRandom: lt,
  isEmpty: ut,
  lowerBarrier: De,
  tryParseNumber: ge,
  upperBarrier: Ae,
  validateString: ct
}, Symbol.toStringTag, { value: "Module" }));
let Z = "https://payments.comgate.cz";
const gt = it.create({
  baseURL: Z
  // timeout: 30000, // default timeout is 0 (no timeout)
});
async function pt(t, e = 200, n = {}, i = {}) {
  return new Promise((r, o) => {
    pe(
      {
        url: t,
        method: "GET",
        params: n
      },
      e,
      r,
      o,
      Fe(i),
      !1,
      // is repeated on ETimeout
      !1,
      // is repeated on ErrorNetwork
      0,
      // networkErrorCount - init val
      0
      // retryCount - init val
    );
  });
}
async function Ne(t, e = 200, n = {}, i = {}, r = {}) {
  return new Promise((o, s) => {
    pe(
      {
        url: t,
        params: n,
        data: i,
        method: "POST"
      },
      e,
      o,
      s,
      Fe(r),
      !1,
      // is repeated on ETimeout
      !1,
      // is repeated on ErrorNetwork
      0,
      // networkErrorCount - init val
      0
      // retryCount - init val
    );
  });
}
function je(t) {
  return typeof t == "string" && /^https:\/\/[a-zA-Z0-9_.-]+/.test(t);
}
function wt(t) {
  let e = !0, n = t;
  n && typeof n == "string" && (n = n.toLowerCase().trim(), n.endsWith("/") && (n = n.slice(0, -1)), n.startsWith("http://") && (!window.Cypress || location.hostname !== "localhost") && (n = n.replace("http://", "https://"), console.warn(`Checkout SDK: Changing HTTP of base domain to HTTPS: '${n}'`)), je(n) && (Z = n, e = !1)), e && console.warn(`Checkout SDK: Invalid base domain: '${n}'. Using default domain: '${Z}'`);
}
function Fe(t) {
  const { maxRetries: e, repeatDelay: n, responseValidator: i } = t;
  return {
    checkSuccessParam: t.checkSuccessParam !== !1,
    // if TRUE response must have {"success": true}
    strictMaxRetries: t.strictMaxRetries !== !1,
    // if TRUE dont allow automatically repeat request on network error
    repeatDelay: H(n, 0, 5e3, !0, !0, 500),
    maxRetries: H(e, 0, 10, !0, !0, 1),
    responseValidator: i && typeof i == "function" ? i : null
  };
}
async function pe(t, e = 200, n, i, r, o = !1, s = !1, c = 0, u = 0) {
  if (!t.url)
    throw new I("Missing URL");
  t.url = Ue(t.url), t.headers || (t.headers = {}), t.headers["X-Requested-With"] = "XMLHttpRequest", t.headers["X-Cg-Origin"] = window.location.origin, gt(t).then((a) => {
    var p;
    if (Array.isArray(e) && (e.length !== 2 || e[0] > e[1]) && console.warn("Invalid successCode array " + JSON.stringify(e) + " the first number must be lower or equal than the second one"), (Array.isArray(e) ? a.status >= e[0] && a.status <= e[1] : a.status === e) && (r.checkSuccessParam && ((p = a.data) == null ? void 0 : p.success) === !0 || !r.checkSuccessParam)) {
      if (delete a.data.success, r.responseValidator) {
        if (r.responseValidator(a.data, a.status, a.headers, a.config) === !0) {
          n(a.data);
          return;
        }
        return Promise.reject({
          msg: "Invalid response by custom validator.",
          valid: !1,
          response: a
        });
      }
      n(a.data);
      return;
    }
    return Promise.reject({
      msg: "Invalid response by basic validation.",
      valid: !1,
      response: a
    });
  }).catch((a) => {
    var Le, _e;
    const E = (a == null ? void 0 : a.code) ?? ((Le = a == null ? void 0 : a.response) == null ? void 0 : Le.code) ?? ((_e = a == null ? void 0 : a.error) == null ? void 0 : _e.code) ?? "Not specified", p = E === "ETIMEDOUT", j = E === "ERR_NETWORK";
    let ae, g;
    (a == null ? void 0 : a.valid) === !1 ? (ae = (a == null ? void 0 : a.msg) || "Invalid response validation", g = a == null ? void 0 : a.response, "response" in a && delete a.response) : (ae = (a == null ? void 0 : a.toString()) ?? "Unknown error", typeof a == "object" && "response" in a && typeof a.response == "object" ? (g = a.response, delete a.response) : g = a);
    let F = c;
    if (p && !o || j && !s ? F += 1 : F = 0, !r.strictMaxRetries && F <= 2 || r.maxRetries > u) {
      let Te = u + 1;
      (p || j) && F < 1 && !r.strictMaxRetries && (Te = u), setTimeout(() => {
        pe(t, e, n, i, r, p, j, F, Te);
      }, r.repeatDelay);
      return;
    }
    i({
      url: t.url,
      message: ae,
      status: (g == null ? void 0 : g.status) ?? null,
      expectedStatus: e,
      requiredSuccessResponseParam: r.checkSuccessParam,
      data: (g == null ? void 0 : g.data) ?? null,
      // request: res?.request ?? null,
      headers: (g == null ? void 0 : g.headers) ?? null,
      // config: res?.config,
      retries: u,
      maxRetries: r.maxRetries,
      errorCode: E,
      networkErrorCountInner: F
      // response se sem dávat nemusí, protože je rozbalený (status, data, config)
    });
  });
}
function Ue(t) {
  let e = t;
  return e.startsWith("/") && (e = Z + e), e;
}
const yt = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  changeBaseDomain: wt,
  expandUrl: Ue,
  get: pt,
  isDomainValid: je,
  post: Ne
}, Symbol.toStringTag, { value: "Module" }));
var Q = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {}, Ve = {}, y = Q && Q.__classPrivateFieldGet || function(t, e, n, i) {
  if (n === "a" && !i)
    throw new TypeError("Private accessor was defined without a getter");
  if (typeof e == "function" ? t !== e || !i : !e.has(t))
    throw new TypeError("Cannot read private member from an object whose class did not declare it");
  return n === "m" ? i : n === "a" ? i.call(t) : i ? i.value : e.get(t);
}, ce = Q && Q.__classPrivateFieldSet || function(t, e, n, i, r) {
  if (i === "m")
    throw new TypeError("Private method is not writable");
  if (i === "a" && !r)
    throw new TypeError("Private accessor was defined without a setter");
  if (typeof e == "function" ? t !== e || !r : !e.has(t))
    throw new TypeError("Cannot write private member to an object whose class did not declare it");
  return i === "a" ? r.call(t, n) : r ? r.value = n : e.set(t, n), n;
}, x, C;
Object.defineProperty(Ve, "__esModule", { value: !0 });
class mt {
  constructor() {
    x.set(this, !1), C.set(this, /* @__PURE__ */ new Set());
  }
  /**
   * Whether the lock is currently acquired or not. Accessing this property does not affect the
   * status of the lock.
   */
  get acquired() {
    return y(this, x, "f");
  }
  /**
   * Acquires the lock, waiting if necessary for it to become free if it is already locked. The
   * returned promise is fulfilled once the lock is acquired.
   *
   * A timeout (in milliseconds) may be optionally provided. If the lock cannot be acquired before
   * the timeout elapses, the returned promise is rejected with an error. The behavior of invalid
   * timeout values depends on how `setTimeout` handles those values.
   *
   * After acquiring the lock, you **must** call `release` when you are done with it.
   */
  acquireAsync({ timeout: e } = {}) {
    if (!y(this, x, "f"))
      return ce(this, x, !0, "f"), Promise.resolve();
    if (e == null)
      return new Promise((r) => {
        y(this, C, "f").add(r);
      });
    let n, i;
    return Promise.race([
      new Promise((r) => {
        n = () => {
          clearTimeout(i), r();
        }, y(this, C, "f").add(n);
      }),
      new Promise((r, o) => {
        i = setTimeout(() => {
          y(this, C, "f").delete(n), o(new Error("Timed out waiting for lock"));
        }, e);
      })
    ]);
  }
  /**
   * Acquires the lock if it is free and otherwise returns immediately without waiting. Returns
   * `true` if the lock was free and is now acquired, and `false` otherwise.
   *
   * This method differs from calling `acquireAsync` with a zero-millisecond timeout in that it runs
   * synchronously without waiting for the JavaScript task queue.
   */
  tryAcquire() {
    return y(this, x, "f") ? !1 : (ce(this, x, !0, "f"), !0);
  }
  /**
   * Releases the lock and gives it to the next waiting acquirer, if there is one. Each acquirer
   * must release the lock exactly once.
   */
  release() {
    if (!y(this, x, "f"))
      throw new Error("Cannot release an unacquired lock");
    if (y(this, C, "f").size > 0) {
      const [e] = y(this, C, "f");
      y(this, C, "f").delete(e), e();
    } else
      ce(this, x, !1, "f");
  }
}
var bt = Ve.default = mt;
x = /* @__PURE__ */ new WeakMap(), C = /* @__PURE__ */ new WeakMap();
function z(t, e) {
  try {
    return ze(X(t, (e == null ? void 0 : e.ignore) ?? [], H(e == null ? void 0 : e.maxDeep, 1, 15, !0, !0, 5), (e == null ? void 0 : e.stringify) === !0, 0), (e == null ? void 0 : e.stringify) === !0, H(e == null ? void 0 : e.maxStringLength, 0, 3e4, !0, !0, 2e4), e == null ? void 0 : e.linePrefixSpace);
  } catch (n) {
    return "Error: " + ((n == null ? void 0 : n.toString()) ?? n.message);
  }
}
function ze(t, e = !1, n = 15e3, i = void 0) {
  return e ? e && typeof t != "string" ? JSON.stringify(t, null, i).slice(0, n) : String(t).slice(0, n) : t;
}
function X(t, e, n, i, r) {
  if (t instanceof Date)
    return i ? t.toISOString() : t;
  if (Array.isArray(t))
    return t.map((o) => X(o, e, n, i));
  if (typeof t == "object" && t !== null) {
    if (n <= 0)
      return "==deeper==";
    if ("data" in t && "status" in t && "statusText" in t && "headers" in t && "config" in t)
      return Me(t);
    if (t instanceof Error) {
      const { name: c, message: u, stack: a, cause: E, context: p } = t, j = { name: c, message: u, stack: a, cause: E };
      return "context" in t && (j.context = p), X(j, e, 3, i);
    }
    const o = ["client", "session", "token", "password", "parentElement", "parentNode", "children", "childNodes", "innerHTML", "outerHTML", "innerText", "ownerDocument", "offsetParent", "ownerElement", "googlepayClient", "window", "self", "document", "top", "parent", "state", "frames", "navigator", "doctype", "documentElement", "body", "head", "defaultView", "scripts", "scrollingElement", "firstElementChild", "lastElementChild", "activeElement", "styleSheets", "adoptedStyleSheets", "firstChild", "lastChild", "srcElement"], s = {};
    for (const c in t) {
      if (e.includes(c)) {
        s[c] = "==ignore==";
        continue;
      }
      if (o.includes(c)) {
        s[c] = "==skip==";
        continue;
      }
      s[c] = X(t[c], e, n - 1, i);
    }
    return s;
  }
  return typeof t == "function" ? "func" : t;
}
function Me(t) {
  var e, n, i, r, o, s, c;
  try {
    return {
      request: {
        base: (e = t.config) == null ? void 0 : e.baseURL,
        // url to which the request led
        url: (n = t.config) == null ? void 0 : n.url,
        // url na kterou vedl request // en:
        data: (i = t.config) == null ? void 0 : i.data,
        // POST data requestu
        params: (r = t.config) == null ? void 0 : r.params,
        // query parametry requestu
        method: (o = t.config) == null ? void 0 : o.method,
        // http metoda (GET, POST, ...)
        timeout: (s = t.config) == null ? void 0 : s.timeout,
        // timeout requestu
        headers: (c = t.config) == null ? void 0 : c.headers
        //hlavičky requestu
      },
      response: {
        status: t.status,
        // návratový status kód našeho API
        data: t.data,
        // response body (json/html)
        headers: t.headers
        // response headers
      }
    };
  } catch (u) {
    return "Axios parse error: " + (u == null ? void 0 : u.message);
  }
}
const St = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  clearedDeepCopy: z,
  convertAxios: Me,
  cutOutput: ze
}, Symbol.toStringTag, { value: "Module" }));
class Pe {
  /**
   * Constructor
   */
  constructor() {
    T(this, "_steps", []);
    T(this, "_start", /* @__PURE__ */ new Date());
    T(this, "_lastStep", /* @__PURE__ */ new Date());
    T(this, "_done", !1);
    this._lastStep = this._start, this._steps.push({
      message: "start",
      time: this._start.toISOString(),
      durationStart: 0
    });
  }
  /**
   * Add a step to the performance log
   * @param message
   * @param context
   */
  step(e, n) {
    const i = /* @__PURE__ */ new Date(), r = {
      message: e,
      time: i.toISOString(),
      durationStart: i.getTime() - this._start.getTime()
    }, o = this._steps.length;
    (o >= 2 || o === 1 && e !== "done") && (this._steps[0].durationStep = 0, r.durationStep = i.getTime() - this._lastStep.getTime()), n && (r.context = n), this._steps.push(r), this._lastStep = i;
  }
  /**
   * Mark the performance as done
   */
  done() {
    this.step("done"), this._done = !0;
  }
  /**
   * Get the performance data
   */
  getData() {
    return this._steps;
  }
  /**
   * Get the performance data as string
   */
  toString() {
    return "Performance: " + JSON.stringify(this.getData()) + ` Final: ${this._done ? "Y" : "N"}`;
  }
}
var v, G, b, M, ee, L, w, P, U, te, We, O, V;
let _ = (v = class {
  /**
   * Logger constructor
   * @param transports
   */
  constructor(e) {
    /**
     * Expand log params
     * @param params
     * @private
     */
    f(this, P);
    /**
     * Increment order number
     * @private
     * @return {number | undefined}
     */
    f(this, te);
    /**
     * Dispatch message to the server
     * @param params
     * @private
     */
    f(this, O);
    f(this, G, new bt());
    f(this, b, {});
    f(this, M, !1);
    f(this, ee, 0);
    f(this, L, 0);
    f(this, w, []);
    if (this.addTransports(e).using <= 0)
      throw new Error("No transports defined.");
  }
  /**
   * Get list of all transports
   */
  getTransports() {
    return l(this, w);
  }
  /**
   * Add transport to logger
   * @param transports
   */
  addTransports(e) {
    const n = Array.isArray(e) ? e : [e];
    let i = 0;
    for (const r of n)
      l(this, w).some((o) => o.getId() === r.getId()) || (l(this, w).push(r), i++);
    return h(this, L, l(this, w).length), { added: i, using: l(this, L) };
  }
  /**
   * Remove transport from logger - at least one transport must remain
   * @param transport
   */
  removeTransports(e) {
    const n = l(this, L), i = l(this, w).filter((o) => e.getId() !== o.getId()), r = i.length;
    return r > 0 ? (h(this, w, i), h(this, L, r), {
      removed: n - r,
      using: r
    }) : {
      removed: 0,
      using: n
    };
  }
  /**
   * Info log
   * @param message
   * @param params
   */
  async info(e, n) {
    return d(this, O, V).call(this, {
      message: e,
      level: v.LEVELS.INFO,
      ...d(this, P, U).call(this, n)
    });
  }
  /**
   * Warn log
   * @param message
   * @param params
   */
  async warn(e, n) {
    return d(this, O, V).call(this, {
      message: e,
      level: v.LEVELS.WARN,
      ...d(this, P, U).call(this, n)
    });
  }
  /**
   * Error log
   * @param message
   * @param params
   */
  async error(e, n) {
    return d(this, O, V).call(this, {
      message: e,
      level: v.LEVELS.ERROR,
      ...d(this, P, U).call(this, n)
    });
  }
  /**
   * Debug log
   * @param message
   * @param params
   */
  async debug(e, n) {
    return d(this, O, V).call(this, {
      message: e,
      level: v.LEVELS.DEBUG,
      ...d(this, P, U).call(this, n)
    });
  }
  /**
   *
   * @param message
   * @param params
   */
  async log(e, n) {
    return d(this, O, V).call(this, {
      message: e,
      level: (n == null ? void 0 : n.level) || v.LEVELS.INFO,
      // eslint-disable-line @typescript-eslint/no-unnecessary-condition
      ...d(this, P, U).call(this, n)
    });
  }
  /**
   * Init performance and start it
   * @returns {LogPerformance} - performance service
   */
  start() {
    return new Pe();
  }
  /**
   * Set global detail value
   * @param value
   * @param key
   * @param value
   */
  with(e, n) {
    return typeof n == "object" ? !1 : n == null ? this.removeWith(e) : (l(this, b)[e] = n, h(this, M, Object.keys(l(this, b)).length > 0), !0);
  }
  /**
   * Remove global detail
   * @param key
   */
  removeWith(e) {
    return e in l(this, b) ? (delete l(this, b)[e], h(this, M, Object.keys(l(this, b)).length > 0), !0) : !1;
  }
  /**
   * Set paymentId detail
   * @param payId
   */
  withPayment(e) {
    return this.with("payment", e);
  }
  /**
   * Set instanceId detail
   * @param payId
   */
  withInstance(e) {
    return this.with("instance", e);
  }
  /**
   * Log with performance
   * @param message
   * @param callback
   * @param params
   */
  async withPerformance(e, n, i) {
    try {
      const r = new Pe();
      let o = null;
      const s = async () => {
        r.done();
        const c = i || { level: "INFO" };
        c.level = c.level || "INFO", c.context = c.context || {}, c.context.performance = r.getData();
        const u = await this.log(e, c);
        return o && o(u), u;
      };
      if (await n({
        step: r.step.bind(r),
        getData: r.getData.bind(r)
      }, s), n.length === 2)
        return new Promise((c) => {
          o = c;
        });
      if (n.length === 1)
        return await s();
    } catch {
    }
    return !1;
  }
}, G = new WeakMap(), b = new WeakMap(), M = new WeakMap(), ee = new WeakMap(), L = new WeakMap(), w = new WeakMap(), P = new WeakSet(), U = function(e) {
  const { code: n, context: i, onlyLocal: r, dispatchNow: o, ignoreLimitCheck: s, date: c } = e ?? {}, u = {
    onlyLocal: r ?? !1,
    dispatchNow: o ?? !1,
    ignoreLimitCheck: s ?? !1
  };
  return n && (u.code = Number(n)), i && (u.context = z(i)), c && (u.date = new Date(c)), l(this, M) && (u.details = z(l(this, b), { maxDeep: 1 })), u;
}, te = new WeakSet(), We = async function() {
  try {
    await l(this, G).acquireAsync();
    try {
      return ++Ce(this, ee)._;
    } catch {
      return;
    } finally {
      l(this, G).release();
    }
  } catch {
    return;
  }
}, O = new WeakSet(), V = async function(e) {
  try {
    if (!e.message || !e.level)
      return !1;
    typeof e.level == "string" && (e.level = e.level.toUpperCase());
    let n = !1, i = !1;
    for (let o = 0; o < l(this, L); o++) {
      const s = l(this, w)[o];
      if (s.canHandleLevel(e.level) && (n = !0, !s.isLocal())) {
        i = !0;
        break;
      }
    }
    if (!n)
      return !1;
    if (i || (e.onlyLocal = !0), e.level === "ERR" && (e.level = "ERROR"), !e.onlyLocal) {
      const o = await d(this, te, We).call(this), s = e.date || /* @__PURE__ */ new Date();
      e.order = o, e.date = s;
      const c = e.details;
      e.details = {
        ...z(c),
        order: o
      };
    }
    let r = 0;
    for (let o = 0; o < l(this, L); o++) {
      const s = l(this, w)[o];
      s.canHandleLevel(e.level) && (!e.onlyLocal || e.onlyLocal && s.isLocal()) && (s.handle(e, l(this, b).payment), r++);
    }
    return r > 0;
  } catch (n) {
    return console.error("Logger error", n), !1;
  }
}, T(v, "LEVELS", {
  DEBUG: "DEBUG",
  INFO: "INFO",
  WARN: "WARN",
  ERROR: "ERROR"
}), v);
var ne, k, W;
const xe = class xe {
  /**
   * TransportBase constructor
   */
  constructor(e, n) {
    f(this, ne, Re());
    f(this, k, {});
    f(this, W, {});
    if (this.constructor === xe)
      throw new Error("Instance of Abstract Class cannot be created");
    h(this, W, e), h(this, k, n);
  }
  /**
   * Get id of transport
   */
  getId() {
    return l(this, ne);
  }
  /**
   * Set config
   * @public
   * @param key
   * @param value
   */
  setConfig(e, n) {
    const i = {};
    return i[e] = n, this.applyConfig(i) === 1;
  }
  /**
   * Get config value
   * @param key
   */
  getConfig(e) {
    if (e in l(this, k))
      return l(this, k)[e];
  }
  /**
   * Check if Service is available on this device
   * @public
   * @param _record
   * @param _paymentId
   * @return {boolean}
   */
  handle(e, n) {
    throw new Error("TransportBase abstract method 'handle' has no implementation");
  }
  /**
   * Get type of transport
   * @public
   * @return {string} type of transport
   */
  getType() {
    throw new Error("TransportBase abstract method 'handle' has no implementation");
  }
  /**
   * Check if Transport is only local (no external communication)
   * @public
   * @return {boolean} true if is local
   */
  isLocal() {
    throw new Error("TransportBase abstract method 'isLocal' has no implementation");
  }
  /**
   * Check if Transport can ignore rate-limiter
   * @public
   * @return {boolean} true if can ignore limit
   */
  canIgnoreLimit() {
    return !1;
  }
  /**
   * Check if Transport can ignore rate-limiter
   * @public
   * @return {boolean} true if can dispatch now
   */
  canDispatchNow() {
    return !1;
  }
  /**
   * Check if Transport can handle specific level
   * @public
   * @param level
   * @return {boolean} true if can handle the level
   */
  canHandleLevel(e) {
    return l(this, k)[e.toLowerCase()] === !0;
  }
  /**
   * Apply more config records
   * @public
   * @param config
   * @return {number} number of applied records
   */
  applyConfig(e) {
    if (!e || typeof e != "object")
      return 0;
    let n = 0;
    for (const i in e) {
      const r = l(this, W)[i], o = Array.isArray(r);
      i in l(this, W) && (!o && typeof e[i] === r || o && r.includes(e[i])) && (l(this, k)[i] = e[i], n++);
    }
    return n;
  }
  /**
   * Black hole to handle uncaught promises
   * @param promise
   */
  hole(e) {
    (e == null ? void 0 : e.catch) === "function" && e.catch((n) => {
      console.warn(`Failed to send logs to Comgate server.
Detail:`, n);
    });
  }
};
ne = new WeakMap(), k = new WeakMap(), W = new WeakMap();
let q = xe;
class Be extends q {
  /**
   * TransportConsole constructor
   * @param config
   */
  constructor(e) {
    super({
      debug: "boolean",
      info: "boolean",
      warn: "boolean",
      error: "boolean",
      prefix: "string"
    }, {
      debug: !0,
      info: !0,
      warn: !0,
      error: !0,
      prefix: ""
    }), super.applyConfig(e);
  }
  /**
   * Check if Service is available on this device
   * @private
   * @param record
   * @param paymentId
   * @return {void}
   */
  handle(e, n) {
    var i, r, o;
    try {
      let s = e.level;
      e.onlyLocal && (s = "LOCAL " + s), e.level === _.LEVELS.DEBUG && (s = ` ${s} `);
      const c = `%c${this.getConfig("prefix")}${s}`;
      let u = "";
      e.order && (u += ` (${e.order})`), e.code && (u += ` <${e.code}>`), (n || (i = e.details) != null && i.payment) && (u += ` [${n || ((r = e.details) == null ? void 0 : r.payment)}]`), (o = e.details) != null && o.instance && (u += ` {${e.details.instance}}`), u += ":";
      const a = [u.trim(), e.message];
      switch (e.context && (a.push(`
Context:`), a.push(e.context)), e.level) {
        case _.LEVELS.DEBUG:
          console.log(c, "color:#fff;background:#444;font-weight: bold", ...a);
          break;
        case _.LEVELS.INFO:
          console.log(c, "color:#0099e5;font-weight: bold", ...a);
          break;
        case _.LEVELS.WARN:
          console.log(c, "color:#FFA500;font-weight: bold", ...a);
          break;
        case _.LEVELS.ERROR:
          console.log(c, "color:#ff221e;font-weight: bold", ...a);
          break;
        default:
          return console.log("Invalid level.", e), !1;
      }
      return !0;
    } catch {
    }
    return !1;
  }
  /**
   * Check if Transport is only local (no external communication)
   * @return {boolean}
   */
  isLocal() {
    return !0;
  }
  /**
   * Check if Transport can ignore rate-limiter
   * @return {boolean}
   */
  canIgnoreLimit() {
    return !0;
  }
  /**
   * Get type of transport
   * @public
   * @return {string} type of transport
   */
  getType() {
    return "ConsoleTransport";
  }
}
const we = typeof Buffer == "function";
typeof TextDecoder == "function" && new TextDecoder();
const Oe = typeof TextEncoder == "function" ? new TextEncoder() : void 0, vt = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=", $ = Array.prototype.slice.call(vt);
((t) => {
  let e = {};
  return t.forEach((n, i) => e[n] = i), e;
})($);
const m = String.fromCharCode.bind(String);
typeof Uint8Array.from == "function" && Uint8Array.from.bind(Uint8Array);
const Et = (t) => t.replace(/=/g, "").replace(/[+\/]/g, (e) => e == "+" ? "-" : "_"), xt = (t) => {
  let e, n, i, r, o = "";
  const s = t.length % 3;
  for (let c = 0; c < t.length; ) {
    if ((n = t.charCodeAt(c++)) > 255 || (i = t.charCodeAt(c++)) > 255 || (r = t.charCodeAt(c++)) > 255)
      throw new TypeError("invalid character found");
    e = n << 16 | i << 8 | r, o += $[e >> 18 & 63] + $[e >> 12 & 63] + $[e >> 6 & 63] + $[e & 63];
  }
  return s ? o.slice(0, s - 3) + "===".substring(s) : o;
}, $e = typeof btoa == "function" ? (t) => btoa(t) : we ? (t) => Buffer.from(t, "binary").toString("base64") : xt, Lt = we ? (t) => Buffer.from(t).toString("base64") : (t) => {
  let n = [];
  for (let i = 0, r = t.length; i < r; i += 4096)
    n.push(m.apply(null, t.subarray(i, i + 4096)));
  return $e(n.join(""));
}, _t = (t) => {
  if (t.length < 2) {
    var e = t.charCodeAt(0);
    return e < 128 ? t : e < 2048 ? m(192 | e >>> 6) + m(128 | e & 63) : m(224 | e >>> 12 & 15) + m(128 | e >>> 6 & 63) + m(128 | e & 63);
  } else {
    var e = 65536 + (t.charCodeAt(0) - 55296) * 1024 + (t.charCodeAt(1) - 56320);
    return m(240 | e >>> 18 & 7) + m(128 | e >>> 12 & 63) + m(128 | e >>> 6 & 63) + m(128 | e & 63);
  }
}, Tt = /[\uD800-\uDBFF][\uDC00-\uDFFFF]|[^\x00-\x7F]/g, Ct = (t) => t.replace(Tt, _t), ke = we ? (t) => Buffer.from(t, "utf8").toString("base64") : Oe ? (t) => Lt(Oe.encode(t)) : (t) => $e(Ct(t)), ue = (t, e = !1) => e ? Et(ke(t)) : ke(t);
var S, D, A, R, B, J, fe, K, de, ie, qe, re, Ge;
class He extends q {
  /**
   * TransportServer constructor
   * @param config
   */
  constructor(n) {
    super({
      debug: "boolean",
      info: "boolean",
      warn: "boolean",
      error: "boolean",
      debounce: "number",
      maxWait: "number",
      maxStack: "number"
    }, {
      debug: !1,
      info: !0,
      warn: !0,
      error: !0,
      debounce: 2e3,
      // 2s - time to wait for next record before dispatch
      maxWait: 1e4,
      // 10s - maximum time if records are still coming faster than debounce (2s)
      maxStack: 20
      // maximum number of records to keep in stack - if exceeded, dispatch immediately
    });
    /**
     * Create debounced dispatcher
     * @private
     */
    f(this, J);
    /**
     * Dispatch all records to server
     */
    f(this, K);
    /**
     * Prepare payload for dispatch
     * @param records
     * @private
     */
    f(this, ie);
    /**
     * Check if limit is not exceeded
     * @private
     */
    f(this, re);
    f(this, S, []);
    f(this, D, void 0);
    f(this, A, void 0);
    f(this, R, {
      aborted: !1,
      time: (/* @__PURE__ */ new Date()).getTime(),
      count: 0
    });
    f(this, B, !1);
    h(this, S, []), super.applyConfig(n), d(this, J, fe).call(this);
  }
  /**
   * Get number of records in queue
   */
  getQueueSize() {
    return l(this, S).length;
  }
  /**
   * Add record to queue and trigger dispatch
   * @param record
   * @param paymentId
   * @return {boolean} true if record was added to queue
   */
  handle(n, i) {
    if ((n.ignoreLimitCheck || [_.LEVELS.WARN, _.LEVELS.ERROR].includes(n.level)) && h(this, B, !0), !d(this, re, Ge).call(this))
      return !1;
    i && h(this, A, i);
    const r = {
      message: n.message,
      level: n.level,
      code: n.code,
      order: n.order,
      date: n.date ?? /* @__PURE__ */ new Date()
    };
    if (n.context && (r.context = z(n.context)), n.details && (r.details = z(n.details)), l(this, S).push(r), n.dispatchNow || l(this, S).length >= this.getConfig("maxStack"))
      this.hole(this.dispatchNow());
    else if (l(this, D))
      l(this, D).call(this);
    else
      return !1;
    return !0;
  }
  /**
   * Dispatch all records to server
   */
  dispatchNow() {
    var n;
    return (n = l(this, D)) == null || n.cancel(), d(this, K, de).call(this);
  }
  /**
   * Apply more config records
   * @public
   * @param config
   * @return {number} number of applied records
   */
  applyConfig(n) {
    const i = super.applyConfig(n);
    if (!n || i <= 0)
      return 0;
    const r = Object.keys(n);
    return r.some((o) => ["debounce", "maxWait"].includes(o)) && d(this, J, fe).call(this), r.includes("maxStack") && l(this, S).length > this.getConfig("maxStack") && this.hole(this.dispatchNow()), i;
  }
  /**
   * Check if Transport is only local (no external communication)
   * @return {boolean}
   */
  isLocal() {
    return !1;
  }
  /**
   * Check if Transport can ignore rate-limiter
   * @return {boolean}
   */
  canIgnoreLimit() {
    return !0;
  }
  /**
   * Check if Transport can ignore rate-limiter
   * @return {boolean}
   */
  canDispatchNow() {
    return !0;
  }
  /**
   * Get type of transport
   * @public
   * @return {string} type of transport
   */
  getType() {
    return "ServerTransport";
  }
}
S = new WeakMap(), D = new WeakMap(), A = new WeakMap(), R = new WeakMap(), B = new WeakMap(), J = new WeakSet(), fe = function() {
  var n;
  (n = l(this, D)) == null || n.flush(), h(this, D, rt(() => this.hole(d(this, K, de).call(this)), this.getConfig("debounce"), { maxWait: this.getConfig("maxWait") }));
}, K = new WeakSet(), de = async function() {
  const n = l(this, S);
  if (h(this, S, []), n.length <= 0)
    return Promise.resolve({
      invalid: !1,
      count: 0,
      loggerEnabled: this.getConfig("enabled"),
      stackSize: n.length,
      server: null,
      error: null
    });
  const i = l(this, B);
  h(this, B, !1);
  const r = d(this, ie, qe).call(this, n);
  return Ne("/checkout/journal", 204, {
    ignoreLimitCheck: i ? 1 : 0,
    ...l(this, A) && { id: l(this, A) }
  }, r, {
    strictMaxRetries: !0,
    maxRetries: 1,
    repeatDelay: 500,
    checkSuccessParam: !1
  }).then(() => Promise.resolve({
    invalid: !1,
    count: n.length
  })).catch((o) => Promise.resolve({
    invalid: !0,
    count: n.length,
    stackSize: this.getConfig("maxStack"),
    error: o
  }));
}, ie = new WeakSet(), qe = function(n) {
  const i = {
    ...l(this, A) && { transId: l(this, A) },
    encoded: !0,
    logs: []
  };
  return i.logs = n.map((r) => {
    const { level: o, date: s, code: c, order: u, message: a, context: E, details: p } = r;
    return {
      level: o,
      dt: s.toISOString(),
      ...c && { code: c },
      ...u && { order: u },
      message: ue(a),
      ...E && { context: ue(JSON.stringify(E)) },
      ...p && { details: ue(JSON.stringify(p)) }
    };
  }), i;
}, re = new WeakSet(), Ge = function() {
  const { aborted: n, time: i, count: r } = l(this, R);
  if (n)
    return !1;
  const o = window.Cypress ? 45 : 5, s = (/* @__PURE__ */ new Date()).getTime();
  return r > o && s - i < 1500 ? (l(this, R).aborted = !0, !1) : (s - i < 1500 ? ++l(this, R).count : (l(this, R).count = 0, l(this, R).time = (/* @__PURE__ */ new Date()).getTime()), !0);
};
function Je(t) {
  return new _(t);
}
const Y = Je([new Be(), new He()]), Pt = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  BaseTransport: q,
  ConsoleTransport: Be,
  Logger: _,
  LoggerInstance: Y,
  ServerTransport: He,
  createLogger: Je,
  default: Y
}, Symbol.toStringTag, { value: "Module" }));
class N {
  /**
   * Validate url address
   * @param url
   * @private
   */
  static validateUrl(e) {
    return /^http(|s):\/\/.*/i.test(e);
  }
  /**
   * Redirect to url by GET method
   * @param url
   * @param redirectMode
   */
  static redirectGet(e, n = "self") {
    if (!e || typeof e != "string" || !N.validateUrl(e))
      throw new I("Cannot GET redirect to invalid URL address.", { ...arguments });
    const i = N.getRedirectWindow(n);
    try {
      i.location.href = e;
    } catch (r) {
      Y.error("Cannot redirect to URL. Use failsafe.", { context: { url: e, redirectMode: n, error: r } }), window.location.href = e;
    }
  }
  /**
   * Redirect to url by POST method (with form submitting)
   */
  static redirectPost(e, n, i, r = "self") {
    if (!e || typeof e != "string" || !N.validateUrl(e))
      throw new I("Cannot POST redirect to invalid URL address.", { ...arguments });
    const o = document.createElement("form");
    o.action = e, o.method = "POST", o.enctype = "multipart/form-data", o.target = N.getFormEscapeTarget(r), document.body.append(o);
    for (const s in n) {
      const c = document.createElement("input");
      c.type = "hidden", c.name = s, c.name = s, c.value = n[s], o.append(c);
    }
    i && (o.enctype = i, o.encoding = i), o.submit();
  }
  /**
   * Redirect to url by submitting form with iframe escape
   */
  static redirectFormPost(e, n = "self") {
    if (!e || typeof e != "string")
      throw new I("Cannot redirect to invalid form.", { formDef: e, redirectMode: n });
    const i = document.createElement("div");
    i.innerHTML = e;
    const r = i.firstChild;
    if (r && r instanceof HTMLFormElement)
      r.target = N.getFormEscapeTarget(n), document.body.append(r), r.submit();
    else
      throw new I("Cannot get form from virtual DIV.", { formDef: e, redirectMode: n });
  }
  /**
   * Return target for redirection
   * @param redirectMode
   * @private
   */
  static getFormEscapeTarget(e = "self") {
    switch (e) {
      case "parent":
        return "_parent";
      case "top":
        return "_top";
      case "self":
      default:
        return "_self";
    }
  }
  /**
   * Get Window object for redirection
   * @private
   */
  static getRedirectWindow(e = "self") {
    return e === "top" && window.top ? window.top : e === "parent" && window.parent ? window.parent : e === "self" && window.self ? window.self : (Y.warn("Unknown redirect mode. Redirecting to self window.", { context: { redirectMode: e } }), window);
  }
}
const oe = "PENDING", ye = "PAID", me = "AUTHORIZED", be = "CANCELLED", Ke = [ye, me, oe, be], Xe = [ye], Ze = [be], Ot = [oe], Qe = [me];
function se(t) {
  return t == null ? void 0 : t.toUpperCase().trim();
}
function kt(t) {
  return Ke.includes(se(t));
}
function Ye(t) {
  return Xe.includes(se(t));
}
function et(t) {
  return Qe.includes(se(t));
}
function Se(t) {
  return Ye(t) || et(t);
}
function ve(t) {
  const e = se(t);
  return Ze.includes(e) || e === "CANCELLED";
}
function Dt(t) {
  return !Se(t) && !ve(t);
}
function he(t) {
  return Se(t) || ve(t);
}
function At(t, e) {
  const n = he(t), i = he(e);
  return n ? t : i ? e : oe;
}
const Rt = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  authorizedState: Qe,
  cancelledState: Ze,
  getFinalOrPending: At,
  isAuthorized: et,
  isCancelled: ve,
  isFinal: he,
  isPaid: Ye,
  isPaidOrAuthorized: Se,
  isPending: Dt,
  isValidStatus: kt,
  paidState: Xe,
  paymentStates: Ke,
  pendingState: Ot,
  statusAuthorized: me,
  statusCancelled: be,
  statusPaid: ye,
  statusPending: oe
}, Symbol.toStringTag, { value: "Module" })), Ee = /[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}/gim;
function It(t) {
  return typeof t == "string" ? Ee.test(t) : !1;
}
function Nt(t = window.location.toString()) {
  var e;
  try {
    const n = Ee.exec(t), i = (e = n == null ? void 0 : n.groups) == null ? void 0 : e.agmo;
    if (i)
      return i;
  } catch (n) {
    console.error(n);
  }
  return null;
}
const jt = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  getPaymentId: Nt,
  isTransIdValid: It,
  transIdRegex: Ee
}, Symbol.toStringTag, { value: "Module" })), zt = {
  Helpers: ht,
  AxiosWrapper: yt,
  Logger: Pt,
  CgError: I,
  Sanitizer: St,
  Redirector: N,
  StatusUtils: Rt,
  TransactionUtils: jt
};
export {
  yt as AxiosWrapper,
  I as CgError,
  ht as Helpers,
  Pt as Logger,
  N as Redirector,
  St as Sanitizer,
  Rt as StatusUtils,
  jt as TransactionUtils,
  zt as default
};
